<template>
    <div>
      <LoadingForm v-if="isLoading"></LoadingForm>
        <div v-else>
            <pre>{{data}}</pre>
        </div>
    </div>
</template>

<script>
    import LoadingForm from "../../components/loaders/LoadingForm";
    export default {
        name: "Show",
        components:{ LoadingForm},
        data() {
            return {
                apiUrl: 'studies',
                data: {},
                isLoading:true
            }
        }, props: {
            id: String
        },
        created() {
            this.prepareComponent()
        },
        methods: {
          async  prepareComponent() {
           await this.getData(this.id).then(({data = {}}) => {
                    this.data = data
                    console.log(data)
                }).catch(response => {

                })
              this.isLoading =  false
            }
        }
    }
</script>

<style scoped>

</style>
