<template>
 <div class="columns is-centered ">
     <div class="column">
         <div class="container">
             <article class="media">
                 <figure class="media-left">
                     <p class="image is-128x128">
                         <b-skeleton circle width="128px" height="128px"></b-skeleton>
                     </p>
                 </figure>
                 <div class="media-content">
                     <div class="content">
                         <div class="block">
                             <div class="notification">
                                 <b-skeleton height="40px"></b-skeleton>
                                 <b-skeleton height="40px"></b-skeleton>
                                 <b-skeleton height="40px"></b-skeleton>
                                 <b-skeleton height="40px"></b-skeleton>
                             </div>

                         </div>
                     </div>
                 </div>
             </article>
         </div>
     </div>
 </div>
</template>

<script>
    export default {
        name: "LoadingForm"
    }
</script>

<style scoped>

</style>
